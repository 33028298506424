<template>
  <nav class="not-prose" aria-label="Progress">
    <ul
      role="list"
      :class="narrow ? '' : 'flex divide-y-0'"
      class="divide-y divide-gray-300 rounded-md border border-gray-300"
    >
      <li
        v-for="(step, stepIdx) in steps"
        :key="step.name"
        :class="narrow ? '' : 'flex flex-1'"
        class="relative"
      >
        <div
          v-if="step.status === 'complete'"
          class="group flex w-full items-center"
        >
          <span class="flex items-center px-6 py-4 text-sm font-medium">
            <span
              class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800"
            >
              <CheckIcon class="h-6 w-6 text-white" aria-hidden="true" />
            </span>
            <span class="ml-4 text-sm font-medium text-gray-900">{{
              step.name
            }}</span>
          </span>
        </div>
        <div
          v-else-if="step.status === 'current'"
          class="flex items-center px-6 py-4 text-sm font-medium"
          aria-current="step"
        >
          <span
            class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600"
          >
            <span class="text-indigo-600">{{ step.id }}</span>
          </span>
          <span class="ml-4 text-sm font-medium text-indigo-600">{{
            step.name
          }}</span>
        </div>
        <div v-else class="group flex items-center">
          <span class="flex items-center px-6 py-4 text-sm font-medium">
            <span
              class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400"
            >
              <span class="text-gray-500 group-hover:text-gray-900">{{
                step.id
              }}</span>
            </span>
            <span
              class="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900"
              >{{ step.name }}</span
            >
          </span>
        </div>
        <template v-if="stepIdx !== steps.length - 1">
          <!-- Arrow separator for lg screens and up -->
          <div
            :class="narrow ? 'hidden' : 'block'"
            class="absolute right-0 top-0 h-full w-5"
            aria-hidden="true"
          >
            <svg
              class="h-full w-full text-gray-300"
              viewBox="0 0 22 80"
              fill="none"
              preserveAspectRatio="none"
            >
              <path
                d="M0 -2L20 40L0 82"
                vector-effect="non-scaling-stroke"
                stroke="currentcolor"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </template>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { CheckIcon } from "@heroicons/vue/24/solid";
import { useWorkspaceLayoutStore } from "@/stores/workspaceLayout";
import { useDocumentationStore } from "@/stores/documentation";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const layoutStore = useWorkspaceLayoutStore();
const { isDesktop } = storeToRefs(layoutStore);
const documentationStore = useDocumentationStore();
const { helpViews } = storeToRefs(documentationStore);

const narrow = computed(() => !isDesktop.value || !helpViews.value);

const steps = [
  { id: "01", name: "Stake", status: "upcoming" },
  { id: "02", name: "Publish", status: "upcoming" },
  { id: "03", name: "Validate", status: "upcoming" },
  { id: "04", name: "Deploy", status: "upcoming" },
];
</script>
